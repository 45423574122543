import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
    ApiError,
    getPopulateAlertsPageResponse,
    getPopulateAlertsPageResponseAdmin,
    postAlertsPageSendMessage,
} from 'src/modules/generated-api-clients/sql';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useApiSqlContext } from 'src/modules/contexts/ApiSqlContext';

export const useQueryAlerts = () => {
    const { client } = useApiSqlContext();
    const { user } = useAuthenticator(context => [context.user]);
    const token = user.getSignInUserSession()?.getIdToken().getJwtToken();

    return useQuery<getPopulateAlertsPageResponseAdmin, ApiError>(
        ['alerts', token],
        async () => {
            if (!client) {
                throw new Error('API client is not initialized');
            }

            if (token === undefined) {
                throw new Error(
                    'AWS Amplify user attributes cannot be undefined'
                );
            }

            try {
                const response =
                    await client.default.getAdminPortalAlertsPopulate(token);
                return response;
            } catch (error: unknown) {
                if (error instanceof ApiError) {
                    console.error(
                        `ApiError -> URL: ${error.url}\nSTATUS: ${error.status}\nMESSAGE: ${
                            error.message
                        }\nBODY: ${JSON.stringify(error.body)}`
                    );
                } else {
                    console.error('Error fetching alerts:', error);
                }
                throw error;
            }
        },
        {
            enabled: !!token,
        }
    );
};

export const useMutationSendMessageAlert = () => {
    const queryClient = useQueryClient();
    const { client } = useApiSqlContext();
    const { user } = useAuthenticator(context => [context.user]);
    const token = user.getSignInUserSession()?.getIdToken().getJwtToken();

    return useMutation<unknown, ApiError, postAlertsPageSendMessage>(
        async requestBody => {
            if (!client) {
                throw new Error('API client is not initialized');
            }
            if (token === undefined) {
                throw new Error(
                    'AWS Amplify user attributes cannot be undefined'
                );
            }

            return client.default.postAdminPortalAlertsSendMessage(
                token,
                requestBody
            );
        },
        {
            onSuccess: (_, variables) => {
                queryClient.invalidateQueries('alerts');
                queryClient.invalidateQueries('client-alerts');
            },
            onError: (error: ApiError) => {
                // Handle ApiError specifically
                console.error(
                    `ApiError -> URL: ${error.url}\nSTATUS: ${error.status}\nMESSAGE: ${
                        error.message
                    }\nBODY: ${JSON.stringify(error.body)}`
                );
            },
        }
    );
};

export const usePutAlertStatusMutation = () => {
    const queryClient = useQueryClient();
    const { client } = useApiSqlContext();
    const { user } = useAuthenticator(context => [context.user]);
    const token = user.getSignInUserSession()?.getIdToken().getJwtToken();

    if (!client) {
        throw new Error('API client is not initialized');
    }

    return useMutation<unknown, ApiError, { alertId: string; status: string }>(
        ({ alertId, status }) => {
            // Guard against errors
            if (!alertId) {
                throw new Error('Invalid Alert Id.');
            }
            if (!status) {
                throw new Error('Invalid status.');
            }
            if (token === undefined) {
                throw new Error(
                    'AWS Amplify user attributes cannot be undefined'
                );
            }

            return client.default.putAdminPortalAlertsUpdateAlertStatus(
                token,
                alertId,
                status
            );
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('alerts');
            },
            onError: (error: unknown) => {
                if (error instanceof ApiError) {
                    // Handle ApiError specifically
                    console.error(
                        `ApiError -> URL: ${error.url}\nSTATUS: ${error.status}\nMESSAGE: ${
                            error.message
                        }\nBODY: ${JSON.stringify(error.body)}`
                    );
                } else {
                    // Handle other errors
                    console.error('Error:', error);
                }
            },
        }
    );
};

export const useQueryGetAlertsByOrganizationId = (clientId: string) => {
    const { client } = useApiSqlContext();
    const { user } = useAuthenticator(context => [context.user]);
    const token = user.getSignInUserSession()?.getIdToken().getJwtToken();

    return useQuery<getPopulateAlertsPageResponse, ApiError>(
        ['client-alerts', token, clientId],
        async () => {
            try {
                if (!client) {
                    throw new Error('API client is not initialized');
                }
                if (token === undefined) {
                    throw new Error(
                        'AWS Amplify user token cannot be undefined'
                    );
                }

                const response =
                    await client.default.getAdminPortalAlertsPopulateByOrganization(
                        token,
                        clientId
                    );

                return response;
            } catch (error: unknown) {
                if (error instanceof ApiError) {
                    console.error(
                        `ApiError -> URL: ${error.url}\nSTATUS: ${error.status}\nMESSAGE: ${
                            error.message
                        }\nBODY: ${JSON.stringify(error.body)}`
                    );
                    throw error;
                } else {
                    console.error('Error fetching account information:', error);
                    throw error;
                }
            }
        },
        {
            enabled: !!token,
        }
    );
};
